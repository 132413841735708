import React from "react";
import { Link, graphql } from "gatsby";
import * as PropTypes from "prop-types";
import Img from "gatsby-image";
import Helmet from "react-helmet";
import Layout from "../../layouts";
import Video from "../Video";

const propTypes = {
  data: PropTypes.object.isRequired,
}

class BlogTemplate extends React.Component {
  render() {
    const article = this.props.data.article
    const {
      title,
      metaDescription,
      lead,
      body,
      video,
      image,
    } = article
    return (
      <Layout>
        <article>
          <Helmet>
            <title>{title}</title>
            <meta name="description" content={metaDescription} />
          </Helmet>
          <div className="panel">
            <div className="row v-align-center content-text">
              <div className="medium-2 columns">
                <Link to="/blog">&#8592; Overzicht</Link>
              </div>
              <div className="medium-10 columns">
                <h1 className="portfolio__title">{title}</h1>
              </div>
            </div>
            {lead &&
              <div className="portfolio__description content-text">
                <p className="portfolio__intro">{lead.lead}</p>
              </div>

            }
            {body &&
              <div
                className="portfolio__description content-text"
                dangerouslySetInnerHTML={{
                  __html: body.childMarkdownRemark.html,
                }}
              />
            }
          </div>
          <div className="panel panel--xlarge">
            {video &&
              <div className="block content-img">
                <Video videoUrl={video}/>
              </div>
            }
            {(!video && image.asset) &&
              <div className="block content-img">
                <Img className="portfolio__image" fluid={image.asset.fluid} alt="" />
              </div>
            }
          </div>
        </article>
      </Layout>
    )
  }
}

BlogTemplate.propTypes = propTypes

export default BlogTemplate

export const pageQuery = graphql`
  query($id: String!) {
    article: sanityBlog(id: { eq: $id }) {
      title
      metaDescription
      lead
      _rawBody
      video
      image {
        _key
        asset {
          id
          fluid(maxWidth: 1000) {
            ...GatsbySanityImageFluid_withWebp
          }
        }
      }
    }
  }
`